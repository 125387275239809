.breadcrumb {
	span {
		padding: $breadcrumb-item-padding-vertical $breadcrumb-item-padding-horizontal;
	}

	li:first-of-type {
		span {
			padding: 0;
		}
	}

	li:last-of-type {
		font-weight: bold;
	}

	li:only-child {
		display: none;
	}
}