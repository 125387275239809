.imageblock-container {
	.image {
		display: flex;
		height: 30rem;

		img {
			object-fit: cover;
			object-position: 50% 55%;
		}
	}
}