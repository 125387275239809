//* Bulma Component Config 	*/
//* v0.7.1 					*/
//*							*/
//***************************/

//*************************************/
//* GENERIC VARIABLES                 */
//*************************************/
$body-background-color: $white !default;
$body-size: 16px !default;
$body-rendering: optimizeLegibility !default;
$body-family: $family-primary !default;
$body-color: $text !default;
$body-weight: $weight-normal !default;
$body-line-height: 1.5 !default;

$code-family: $family-code !default;
$code-padding: 0.25em 0.5em 0.25em !default;
$code-weight: normal !default;
$code-size: 0.875em !default;

$hr-background-color: $background !default;
$hr-height: 2px !default;
$hr-margin: 1.5rem 0 !default;

$strong-color: $text-strong !default;
$strong-weight: $weight-bold !default;

//***************************/
//* GLOBAL UI               */
//***************************/
$control-radius: $radius !default;
$control-radius-small: $radius-small !default;

$control-border-width: 1px !default;

$control-padding-vertical: calc(0.375em - #{$control-border-width}) !default;
$control-padding-horizontal: calc(0.625em - #{$control-border-width}) !default;

//*************************************/
//* LAYOUT COMPONENTS                 */
//*************************************/

//***************************/
//* SECTION                 */
//***************************/
$section-padding: 3rem 1.5rem !default;
$section-padding-medium: 9rem 1.5rem !default;
$section-padding-large: 18rem 1.5rem !default;


//***************************/
//* FOOTER                  */
//***************************/
$footer-background-color: $dark !default;



//*************************************/
//* FORM COMPONENTS                   */
//*************************************/

//***************************/
//* GENERAL                 */
//***************************/
$control-radius: $radius !default;
$control-radius-small: $radius-small !default;
$control-padding-vertical: calc(0.375em - 1px) !default;
$control-padding-horizontal: calc(0.625em - 1px) !default;

$label-color: $grey-darker !default;
$label-weight: $weight-bold !default;

$help-size: $size-small !default;


//***************************/
//* INPUTS                  */
//***************************/
$input-color: $grey-darker !default;
$input-background-color: $white !default;
$input-border-color: $grey-lighter !default;
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;

$input-hover-color: $grey-darker !default;
$input-hover-border-color: $grey-light !default;

$input-focus-color: $grey-darker !default;
$input-focus-border-color: $link !default;
$input-focus-box-shadow-size: 0 0 0 0.125em !default;
$input-focus-box-shadow-color: rgba($link, 0.25) !default;

$input-disabled-color: $text-light !default;
$input-disabled-background-color: $background !default;
$input-disabled-border-color: $background !default;

$input-arrow: $link !default;

$input-icon-color: $grey-lighter !default;
$input-icon-active-color: $grey !default;

$input-radius: $radius !default;


//***************************/
//* FILE INPUT              */
//***************************/
$file-border-color: $border !default;
$file-radius: $radius !default;

$file-cta-background-color: $white-ter !default;
$file-cta-color: $grey-dark !default;
$file-cta-hover-color: $grey-darker !default;
$file-cta-active-color: $grey-darker !default;

$file-name-border-color: $border !default;
$file-name-border-style: solid !default;
$file-name-border-width: 1px 1px 1px 0 !default;
$file-name-max-width: 16em !default;



//*************************************/
//* ELEMENTS                          */
//*************************************/

//***************************/
//* BOX                     */
//***************************/
$box-color: $text !default;
$box-background-color: $white !default;
$box-radius: $radius-large !default;
$box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;
$box-padding: 1.25rem !default;

$box-link-hover-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px $link !default;
$box-link-active-shadow: inset 0 1px 2px rgba($black, 0.2), 0 0 0 1px $link !default;


//***************************/
//* BUTTON                  */
//***************************/
$button-color: $grey-darker !default;
$button-background-color: $white !default;

$button-border-color: $grey-lighter !default;
$button-border-width: $control-border-width !default;

$button-padding-vertical: calc(0.375em - #{$button-border-width}) !default;
$button-padding-horizontal: 0.75em !default;

$button-hover-color: $link-hover !default;
$button-hover-border-color: $link-hover-border !default;

$button-focus-color: $link-focus !default;
$button-focus-border-color: $link-focus-border !default;
$button-focus-box-shadow-size: 0 0 0 0.125em !default;
$button-focus-box-shadow-color: rgba($link, 0.25) !default;

$button-active-color: $link-active !default;
$button-active-border-color: $link-active-border !default;

$button-text-color: $text !default;
$button-text-hover-background-color: $background !default;
$button-text-hover-color: $text-strong !default;

$button-disabled-background-color: $white !default;
$button-disabled-border-color: $grey-lighter !default;
$button-disabled-shadow: none !default;

$button-disabled-opacity: 0.5 !default;

$button-static-color: $grey !default;
$button-static-background-color: $white-ter !default;
$button-static-border-color: $grey-lighter !default;


//***************************/
//* CONTENT (CMS) CONTAINER */
//***************************/
$content-heading-color: $text-strong !default;
$content-heading-weight: $weight-semibold !default;
$content-heading-line-height: 1.125 !default;

$content-blockquote-background-color: $background !default;
$content-blockquote-border-left: 5px solid $border !default;
$content-blockquote-padding: 1.25em 1.5em !default;

$content-pre-padding: 1.25em 1.5em !default;

$content-table-cell-border: 1px solid $border !default;
$content-table-cell-border-width: 0 0 1px !default;
$content-table-cell-padding: 0.5em 0.75em !default;
$content-table-cell-heading-color: $text-strong !default;
$content-table-head-cell-border-width: 0 0 2px !default;
$content-table-head-cell-color: $text-strong !default;
$content-table-foot-cell-border-width: 2px 0 0 !default;
$content-table-foot-cell-color: $text-strong !default;



//***************************/
//* ICON                    */
//***************************/
$icon-dimensions: 1.5rem !default;
$icon-dimensions-small: 1rem !default;
$icon-dimensions-medium: 2rem !default;
$icon-dimensions-large: 3rem !default;


//***************************/
//* THUMBNAIL IMAGES        */
//***************************/
$dimensions: 16 24 32 48 64 96 128 !default;


//***************************/
//* NOTIFICATION            */
//***************************/
$notification-background-color: $background !default;
$notification-radius: $radius !default;
$notification-padding: 1.25rem 2.5rem 1.25rem 1.5rem !default;


//***************************/
//* PROGRESS BAR            */
//***************************/
$progress-bar-background-color: $border !default;
$progress-value-background-color: $text !default;


//***************************/
//* TABLE                   */
//***************************/
$table-color: $grey-darker !default;
$table-background-color: $white !default;

$table-cell-border: 1px solid $grey-lighter !default;
$table-cell-border-width: 0 0 1px !default;
$table-cell-padding: 0.5em 0.75em !default;
$table-cell-heading-color: $text-strong !default;

$table-head-cell-border-width: 0 0 2px !default;
$table-head-cell-color: $text-strong !default;
$table-foot-cell-border-width: 2px 0 0 !default;
$table-foot-cell-color: $text-strong !default;

$table-row-hover-background-color: $white-bis !default;

$table-row-active-background-color: $primary !default;
$table-row-active-color: $primary-invert !default;

$table-striped-row-even-background-color: $white-bis !default;
$table-striped-row-even-hover-background-color: $white-ter !default;


//***************************/
//* TAG                     */
//***************************/
$tag-background-color: $background !default;
$tag-color: $text !default;
$tag-radius: $radius !default;
$tag-delete-margin: 1px !default;


//***************************/
//* TITLES & SUBTITLES      */
//***************************/
$title-color: $grey-darker !default;
$title-size: $size-3 !default;
$title-weight: $weight-semibold !default;
$title-line-height: 1.125 !default;
$title-strong-color: inherit !default;
$title-strong-weight: inherit !default;
$title-sub-size: 0.75em !default;
$title-sup-size: 0.75em !default;

$subtitle-color: $grey-dark !default;
$subtitle-size: $size-5 !default;
$subtitle-weight: $weight-normal !default;
$subtitle-line-height: 1.25 !default;
$subtitle-strong-color: $grey-darker !default;
$subtitle-strong-weight: $weight-semibold !default;
$subtitle-negative-margin: -1.25rem !default;


//*************************************/
//* PAGE COMPONENTS                   */
//*************************************/

//***************************/
//* BREADCRUMB              */
//***************************/
$breadcrumb-item-color: $link !default;
$breadcrumb-item-hover-color: $link-hover !default;
$breadcrumb-item-active-color: $text-strong !default;

$breadcrumb-item-padding-vertical: 0 !default;
$breadcrumb-item-padding-horizontal: 0.75em !default;

$breadcrumb-item-separator-color: $grey-light !default;


//***************************/
//* CARD                    */
//***************************/
$card-color: $text !default;
$card-background-color: $white !default;
$card-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

$card-header-background-color: transparent !default;
$card-header-color: $text-strong !default;
$card-header-shadow: 0 1px 2px rgba($black, 0.1) !default;
$card-header-weight: $weight-bold !default;

$card-content-background-color: transparent !default;

$card-footer-background-color: transparent !default;
$card-footer-border-top: 1px solid $border !default;


//***************************/
//* DROPDOWN                */
//***************************/
$dropdown-content-background-color: $white !default;
$dropdown-content-arrow: $link !default;
$dropdown-content-offset: 4px !default;
$dropdown-content-radius: $radius !default;
$dropdown-content-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;
$dropdown-content-z: 20 !default;

$dropdown-item-color: $grey-dark !default;
$dropdown-item-hover-color: $black !default;
$dropdown-item-hover-background-color: $background !default;
$dropdown-item-active-color: $link-invert !default;
$dropdown-item-active-background-color: $link !default;

$dropdown-divider-background-color: $border !default;


//***************************/
//* MENU                    */
//***************************/
$menu-item-color: $text !default;
$menu-item-radius: $radius-small !default;
$menu-item-hover-color: $text-strong !default;
$menu-item-hover-background-color: $background !default;
$menu-item-active-color: $link-invert !default;
$menu-item-active-background-color: $link !default;

$menu-list-border-left: 1px solid $border !default;

$menu-label-color: $text-light !default;


//***************************/
//* MESSAGE                 */
//***************************/
$message-background-color: $background !default;
$message-radius: $radius !default;

$message-header-background-color: $text !default;
$message-header-color: $text-invert !default;
$message-header-weight: $weight-bold !default;
$message-header-padding: 0.75em 1em !default;
$message-header-radius: $radius !default;

$message-body-border-color: $border !default;
$message-body-border-width: 0 0 0 4px !default;
$message-body-color: $text !default;
$message-body-padding: 1.25em 1.5em !default;
$message-body-radius: $radius !default;

$message-body-pre-background-color: $white !default;
$message-body-pre-code-background-color: transparent !default;

$message-header-body-border-width: 0 !default;


//***************************/
//* MODAL                   */
//***************************/
$modal-z: 40 !default;

$modal-background-background-color: rgba($black, 0.86) !default;

$modal-content-width: 640px !default;
$modal-content-margin-mobile: 20px !default;
$modal-content-spacing-mobile: 160px !default;
$modal-content-spacing-tablet: 40px !default;

$modal-close-dimensions: 40px !default;
$modal-close-right: 20px !default;
$modal-close-top: 20px !default;

$modal-card-spacing: 40px !default;

$modal-card-head-background-color: $background !default;
$modal-card-head-border-bottom: 1px solid $border !default;
$modal-card-head-padding: 20px !default;
$modal-card-head-radius: $radius-large !default;

$modal-card-title-color: $text-strong !default;
$modal-card-title-line-height: 1 !default;
$modal-card-title-size: $size-4 !default;

$modal-card-foot-radius: $radius-large !default;
$modal-card-foot-border-top: 1px solid $border !default;

$modal-card-body-background-color: $white !default;
$modal-card-body-padding: 20px !default;


//***************************/
//* NAVBAR                  */
//***************************/
$navbar-background-color: $white !default;
$navbar-box-shadow-size: 0 2px 0 0 !default;
$navbar-box-shadow-color: $background !default;
$navbar-height: 3.25rem !default;
$navbar-padding-vertical: 1rem !default;
$navbar-padding-horizontal: 2rem !default;
$navbar-z: 30 !default;
$navbar-fixed-z: 30 !default;

$navbar-item-color: $grey-dark !default;
$navbar-item-hover-color: $link !default;
$navbar-item-hover-background-color: $white-bis !default;
$navbar-item-active-color: $black !default;
$navbar-item-active-background-color: transparent !default;
$navbar-item-img-max-height: 1.75rem !default;

$navbar-tab-hover-background-color: transparent !default;
$navbar-tab-hover-border-bottom-color: $link !default;
$navbar-tab-active-color: $link !default;
$navbar-tab-active-background-color: transparent !default;
$navbar-tab-active-border-bottom-color: $link !default;
$navbar-tab-active-border-bottom-style: solid !default;
$navbar-tab-active-border-bottom-width: 3px !default;

$navbar-dropdown-background-color: $white !default;
$navbar-dropdown-border-top: 2px solid $border !default;
$navbar-dropdown-offset: -4px !default;
$navbar-dropdown-arrow: $link !default;
$navbar-dropdown-radius: $radius-large !default;
$navbar-dropdown-z: 20 !default;

$navbar-dropdown-boxed-radius: $radius-large !default;
$navbar-dropdown-boxed-shadow: 0 8px 8px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;

$navbar-dropdown-item-hover-color: $black !default;
$navbar-dropdown-item-hover-background-color: $background !default;
$navbar-dropdown-item-active-color: $link !default;
$navbar-dropdown-item-active-background-color: $background !default;

$navbar-divider-background-color: $background !default;
$navbar-divider-height: 2px !default;

$navbar-bottom-box-shadow-size: 0 -2px 0 0 !default;


//***************************/
//* PAGINATION              */
//***************************/
$pagination-color: $grey-darker !default;
$pagination-border-color: $grey-lighter !default;
$pagination-margin: -0.25rem !default;

$pagination-hover-color: $link-hover !default;
$pagination-hover-border-color: $link-hover-border !default;

$pagination-focus-color: $link-focus !default;
$pagination-focus-border-color: $link-focus-border !default;

$pagination-active-color: $link-active !default;
$pagination-active-border-color: $link-active-border !default;

$pagination-disabled-color: $grey !default;
$pagination-disabled-background-color: $grey-lighter !default;
$pagination-disabled-border-color: $grey-lighter !default;

$pagination-current-color: $link-invert !default;
$pagination-current-background-color: $link !default;
$pagination-current-border-color: $link !default;

$pagination-ellipsis-color: $grey-light !default;

$pagination-shadow-inset: inset 0 1px 2px rgba($black, 0.2) !default;


//***************************/
//* PANEL                   */
//***************************/
$panel-item-border: 1px solid $border !default;

$panel-heading-background-color: $background !default;
$panel-heading-color: $text-strong !default;
$panel-heading-line-height: 1.25 !default;
$panel-heading-padding: 0.5em 0.75em !default;
$panel-heading-radius: $radius !default;
$panel-heading-size: 1.25em !default;
$panel-heading-weight: $weight-light !default;

$panel-tab-border-bottom: 1px solid $border !default;
$panel-tab-active-border-bottom-color: $link-active-border !default;
$panel-tab-active-color: $link-active !default;

$panel-list-item-color: $text !default;
$panel-list-item-hover-color: $link !default;

$panel-block-color: $text-strong !default;
$panel-block-hover-background-color: $background !default;
$panel-block-active-border-left-color: $link !default;
$panel-block-active-color: $link-active !default;
$panel-block-active-icon-color: $link !default;

$panel-icon-color: $text-light !default;


//***************************/
//* TABS                    */
//***************************/
$tabs-border-bottom-color: $border !default;
$tabs-border-bottom-style: solid !default;
$tabs-border-bottom-width: 1px !default;
$tabs-link-color: $text !default;
$tabs-link-hover-border-bottom-color: $text-strong !default;
$tabs-link-hover-color: $text-strong !default;
$tabs-link-active-border-bottom-color: $link !default;
$tabs-link-active-color: $link !default;
$tabs-link-padding: 0.5em 1em !default;

$tabs-boxed-link-radius: $radius !default;
$tabs-boxed-link-hover-background-color: $background !default;
$tabs-boxed-link-hover-border-bottom-color: $border !default;

$tabs-boxed-link-active-background-color: $white !default;
$tabs-boxed-link-active-border-color: $border !default;
$tabs-boxed-link-active-border-bottom-color: transparent !default;

$tabs-toggle-link-border-color: $border !default;
$tabs-toggle-link-border-style: solid !default;
$tabs-toggle-link-border-width: 1px !default;
$tabs-toggle-link-hover-background-color: $background !default;
$tabs-toggle-link-hover-border-color: $border-hover !default;
$tabs-toggle-link-radius: $radius !default;
$tabs-toggle-link-active-background-color: $link !default;
$tabs-toggle-link-active-border-color: $link !default;
$tabs-toggle-link-active-color: $link-invert !default;