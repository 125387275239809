.card {	
	.card-content {
		padding: 1.5rem 0;
		
		.title {
			margin-bottom: 1rem;
		}
	}

	&:hover {
		opacity: 0.5;
	}

	&:focus, & *:focus {
		outline: none;
	}

	a {
		opacity: 1;
		transition: opacity 150ms ;
	}

	p, span {
		color: $body-color;
	}
}