$arrow-position: -50px;
$showcase-arrow-position: 25px;

.carousel {
	/* the slides */
	.slick-slide {
		margin: 0 $column-gap;

		&:focus {
			outline: none;
		}
	}
	/* the parent */
	.slick-list {
		margin: 0 -#{$column-gap};
	}
}

[class*="carousel"] {
	.arrow {
		position: absolute;
		top: 50%;
		width: 20px;
		height: 60px;
		cursor: pointer;
		opacity: 1;
		transform: translateY(-50%);
		z-index: 2;

		&:hover {
			.arrow-top, .arrow-bottom {
				background-color: $primary;
			}
		}

		&:active {
			transform: translateY(-50%) scale(0.9);
		}

		.arrow-top, .arrow-bottom {
			position: absolute;
			top: 50%;
			width: 100%;			
			height: 4px;
			background-color: $body-color;
		}

		&.arrow-prev {
			left: $arrow-position;
			
			.arrow-top, .arrow-bottom {
				right: -5px;
			}

			.arrow-top {
				transform: rotate(-45deg);
				transform-origin: bottom left;
			}

			.arrow-bottom {
				transform: rotate(45deg);
				transform-origin: top left;
			}
		}

		&.arrow-next {
			right: $arrow-position;
			
			.arrow-top, .arrow-bottom {
				left: -5px;
			}
			
			.arrow-top {
				transform: rotate(45deg);
				transform-origin: bottom right;
			}

			.arrow-bottom {
				transform: rotate(-45deg);
				transform-origin: top right;
			}
		}

		&.slick-disabled {
			display: none !important;
		}
	}

}

.carousel-showcase {
	.slick-slide img {
		width: 100%;
		cursor: default;
	}

	.arrow {
		&.arrow-prev {
			left: $showcase-arrow-position;
		}
		&.arrow-next {
			right: $showcase-arrow-position;
		}

		filter: drop-shadow(0px 2px 1px $black);

		&:hover {
			.arrow-top, .arrow-bottom {
				background-color: white;
			}
		}

		.arrow-top, .arrow-bottom {
			background-color: $white;
		}
	}
}

.carousel-thumbnails {
	margin-top: 0.4rem;
	.slick-list {
		margin: 0 -0.2rem;
	}
	.slick-track {
		margin-left: 0;
	}
	.slick-slide {
		max-width: 100px;
		margin: 0 0.2rem;
		opacity: 0.5;
		&.is-active {
			opacity: 1;
		}

		&:focus {
			outline: none;
		}
	}
}

@include until($desktop) {
	.carousel-container {
		width: calc(100% - #{2 * $column-gap});
		margin-left: auto;
    	margin-right: auto;
	}
}