.title, .subtitle {
	&.is-primary {
		color: $primary;
	}
	&.is-primary-invert {
		color: $primary-invert;
	}
}

.title {
	font-weight: 600;
}

.subtitle {
	font-weight: 500;
}