// 1. Import the initial variables
@import "../../../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../../../node_modules/bulma/sass/utilities/functions";

// 2. Set your own initial variables
$blue: #005482;
$blue-invert: #ffffff;
$white: #f2f2f2;
$black: #020203;

$body-size: 16px;
// $navbar-height: 4.5rem;
// $navbar-padding-vertical: 0;

// Add a font-family
@font-face {
	font-family: 'TT Hazelnut';
	src: url('../fonts/tthazelnuts-bold.woff2') format('woff2'),
		 url('../fonts/tthazelnuts-bold.woff') format('woff');
	font-weight: 600;
}
@font-face {
	font-family: 'TT Hazelnut';
	src: url('../fonts/tthazelnuts-medium.woff2') format('woff2'),
		 url('../fonts/tthazelnuts-medium.woff') format('woff');
	font-weight: 500;
}
@font-face {
	font-family: 'TT Hazelnut';
	src: url('../fonts/tthazelnuts-regular.woff2') format('woff2'),
		 url('../fonts/tthazelnuts-regular.woff') format('woff');
	font-weight: 400;
}
@font-face {
	font-family: 'TT Hazelnut';
	src: url('../fonts/tthazelnuts-light.woff2') format('woff2'),
		 url('../fonts/tthazelnuts-light.woff') format('woff');
	font-weight: 200;
}
$family-sans-serif: "TT Hazelnut", sans-serif;

// 3. Set the derived variables
$primary: $blue;
$primary-invert: $blue-invert;
$secondary: $black;
$secondary-invert: $white;

$navbar-background-color: $secondary;
$navbar-item-color: $secondary-invert;
$footer-background-color: $white;

$column-gap: 1.75rem;
$column-gap-mobile: 3.75rem;
// Use the new serif family
$family-primary: $family-sans-serif;


$card-shadow: none;

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

// 5. Add new color variables to the color map.
@import "../../../../node_modules/bulma/sass/utilities/derived-variables";
$addColors: (
  "twitter":($twitter, $twitter-invert),
  "linkedin": ($linkedin, $linkedin-invert),
  "github": ($github, $github-invert)
);
$colors: map-merge($colors, $addColors);

// Import Bulma component configuration
// All Bulma's stock Component variables added to one central scss file for easy theme customization.
@import "theme/variables.scss";

// 6. Import the rest of Bulma
@import "../../../../node_modules/bulma/bulma";
@import "../../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../../node_modules/slick-carousel/slick/slick-theme.scss";




// Project specific styles

/* Columns gap fix 
   https://github.com/jgthms/bulma/issues/1540\
*/
.container {
	padding-left: $column-gap;
	padding-right: $column-gap;
	width: 100%;

	@include until($desktop) {
		padding-left: $column-gap-mobile;
		padding-right: $column-gap-mobile;
	}
}
body, section {
	background-color: $footer-background-color;
}
section:not(:last-child) {
	margin-bottom: 5rem;
}

.is-centered {
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	&.is-text-left {
		text-align: left;
	}
}

.is-max-1920 {
	max-width: 1920px;
	margin: 0 auto;
}

//Woocommerce CSS
.woocommerce-container {
	margin-bottom: 2rem !important;

	.term-description {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.notification {
		padding: 1.25rem 2.5rem;
		.delete {
			left: .5rem;
			right: initial;
		}
		.button {
			float: right;
			padding: 0;
			height: initial;
			background-color: transparent;
			border: none;
			color: white;

			&:after {
				content: '\2192';
				padding-left: .5rem;
			}
		}
	}
}

.woocommerce-tabs {
	.tab {
		margin-top: 1.5rem;
	}
	.woocommerce-product-attributes {
		display: block;
		tbody {
			display: flex;
			flex-flow: column wrap;
			align-items: center;
		}

		tr {
			display: flex;
		}

		th {
			margin-right: 1rem;
		}
	}
}

form.cart {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin: 1.5rem 0;

	input {
		width: 75px;
		margin-right: .5rem;
	}
}

.related.products {
	margin-top: 4rem;
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

@import "basics/__basics",
		"components/__components",
		"structures/__structures";