.navbar .navbar-menu {
	.navbar-item {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.has-dropdown .navbar-item {
		margin-left: 0;
		margin-right: 0;
	}

	.navbar-item.has-dropdown {

		&:hover {
			.navbar-link {
				color: $primary;
			}
		}
	}

	.navbar-dropdown {
		.navbar-item {
			color: $black;
		}
	}

	.navbar-item[class*="xoo-wsc"] {
		span {
			margin: 0 3px;

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}
.navbar-burger {
	color: $light;
	&:hover,
	&.is-active {
		background-color: darken($primary, 5%);
		color: inherit;
	}
}


.navbar > .container .navbar-menu,
.container > .navbar .navbar-menu {
	margin-right: 0;
	@include touch {
		margin-left: -0.75rem;
		margin-right: -0.75rem;
	}
}

.navbar > .container .navbar-brand,
.container > .navbar .navbar-brand {
	margin-left: -0.75rem;
}
.navbar > .container > .navbar-end,
.container > .navbar > .navbar-end {
	margin-right: -0.75rem;
}