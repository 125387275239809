.hero {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;

	margin-bottom: 4rem !important;

	.logo {
		height: 19rem;
	}

	.hero-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		&:after {
			content:'';
			position:absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.4);
		}

		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
			margin: 0 auto;
		}
	}
}

.hero.is-medium,
.hero.is-large,
.hero.is-fullheight {
	.hero-body {
		align-items: center;
		display: flex;
		padding: 3rem 1.5rem;
	}
}
.hero.is-medium {
	height: 25rem;
}
.hero.is-large {
	height: 35rem;
}