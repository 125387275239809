.textblock-container {
	.title {
		margin-bottom: 1rem;
	}

	.is-quote {
		p {
			font-size: 1.5rem;
		}
	}
}