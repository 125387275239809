.socialmedia {
	display: flex;
	justify-content: center;

	figure {
		margin: 0 0.2rem;
	}

	svg {
		fill: $primary;
	}
}