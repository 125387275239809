.video {
	position: relative;
	width: 100%;
	height: auto;
	object-fit: cover;
	&:focus, & *:focus {
		outline: none;
	}
	font-family: $family-primary;

	&.is-3by1 {
		padding-top: 33.33%;
	}
	&.is-2by1 {
		padding-top: 50%;
	}
	&.is-16by9 {
		padding-top: 56.25%;
	}
	&.is-3by2 {
		padding-top: 66.66%;
	}
	&.is-4by3 {
		padding-top: 75%;
	}
	&.is-1by1 {
		padding-top: 100%;
	}
	&.is-3by4 {
		padding-top: 133.33%;
	}
	&.is-2by3 {
		padding-top: 150%;
	}
	&.is-9by16 {
		padding-top: 177.77%;
	}
	&.is-1by2 {
		padding-top: 200%;
	}
	&.is-1by3 {
		padding-top: 300%;
	}
}

// Video player skin settings
$primary-foreground-color: $primary-invert;
$primary-background-color: $primary;

$slider-bg-color: lighten($primary-background-color, 33%);

.video-js {
	font-size: 12px;
	color: $primary-foreground-color;

	.vjs-control-bar, .vjs-big-play-button, .vjs-menu-button .vjs-menu-content {
		background-color: $primary-background-color; /* IE8 */
		background-color: rgba($primary-background-color, 0.7);
	}

	.vjs-big-play-button {
		font-size: 3em;

		$big-play-width: 2em;
		$big-play-height: 2em;

		line-height: $big-play-height;
		height: $big-play-height;
		width: $big-play-width;

		border: 0.06666em solid $primary-foreground-color;
		border-radius: 0.6em;
		left: 50%;
		top: 50%;
		margin-left: - ($big-play-width / 2);
		margin-top: - ($big-play-height / 2);
	}

	.vjs-slider {
		background-color: $slider-bg-color;
		background-color: rgba($slider-bg-color, 0.5);
	}

	.vjs-volume-level, .vjs-play-progress, .vjs-slider-bar {
		background: $primary-foreground-color;
	}

	.vjs-load-progress {
		background: lighten($slider-bg-color, 25%);
		background: rgba($slider-bg-color, 0.5);
	}

	.vjs-load-progress div {
		background: lighten($slider-bg-color, 50%);  /* IE8 */
		background: rgba($slider-bg-color, 0.75);
	}
}
