.contact-container {
	form {
		.column {
			padding-top: 0;

			&:last-of-type {
				padding-bottom: 0;
			}
		}

		.button {
			display: block;
		}
	}

	.wpcf7-response-output {
		margin: 0 !important;
		padding: $notification-padding !important;
		border: none !important;
	}
}